<template>
  <v-card class="my-10 pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" lg="6">
          <div class="font-weight-bold my-5">
            <h4>Upload File Alarm</h4>
          </div>
          <v-file-input
            v-model="file"
            color="info lighten-2"
            label="File input"
            placeholder="Select your files"
            prepend-icon="mdi-paperclip"
            outlined
            :show-size="1000"
            solo
            accept=".xlsx, .xls"
            :rules="rules.required"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" color="info lighten-2" dark label small>
                {{ text }}
              </v-chip>

              <span
                v-else-if="index === 2"
                class="text-overline grey--text text--darken-3 mx-2"
              >
                +{{ file.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>
        </v-col>
      </v-row>
    </v-form>

    <v-divider class="my-5 divider-card"></v-divider>

    <v-card-actions>
      <v-row dense>
        <v-btn color="info lighten-2" @click="submit"> Upload </v-btn>
      </v-row>
    </v-card-actions>

    <!-- DIALOG FAILED -->
    <v-dialog v-model="dialog_failed" max-width="500" persistent>
      <v-card class="pt-5">
        <v-row align="center" justify="center">
          <v-icon color="error" size="100"> mdi-alert </v-icon>
        </v-row>

        <v-row align="center" justify="center">
          <v-card-title class="caption">
            PEMROSESAN DOKUMEN GAGAL
          </v-card-title>
        </v-row>

        <v-card-text>
          Terdapat kesalahan dalam file yang anda upload:

          <ul class="my-3">
            <li>
              <b>{{ failed_message }}</b>
            </li>
          </ul>

          Segera ubah data sesuai dengan format yang sudah ditentukan
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info lighten-2"
            @click="
              dialog_failed = false;
              file = null;
            "
          >
            Upload Ulang
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- LOADING BAR -->
    <v-overlay :value="overlay" z-index="999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import JwtServices from '../../../services/jwt.services'

export default {
  name: "submit-alarm-upload-file",
  data: () => ({
    user: null,
    file: null,
    dialog_failed: false,
    overlay: false,
    valid: true,
    rules: {
      required: [(v) => !!v || "Field is required"],
    },
    failed_message: null,
    response: null,
  }),
  mounted() {
    this.user = JwtServices.getUser();
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    submit() {
      if (!this.validate()) {
        return;
      }
      this.overlay = true;

      let formData = new FormData();
      formData.append("attachment", this.file);

      fetch(
        process.env.VUE_APP_API_URL +
          `api/v1/${this.user.language}/upload/alarm`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ` + this.user.token,
          },
        }
      )
        .then((response) => {
          this.response = response;
          return response.json();
        })
        .then((result) => {
          this.overlay = false;

          // Check error
          if (result.error == true && this.response.status == 422) {
            this.$swal("Opps", result.message, "error");
            return;
          }

          if (result.error == true && this.response.status == 400) {
            this.failed_message = result.message;
            this.dialog_failed = true;
            return;
          }
          this.$router.push({ name: "Submit Alarm - Success" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
